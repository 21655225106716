@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #007bff;
  --border-color: #e0e0e0;
  --text-color: #333;
  --text-secondary: #6c757d;
}

body {
  font-family: "Poppins", sans-serif;
}
/* Define custom styles for SweetAlert2 buttons */
.custom-swal-confirm-button {
  background-color: #F38D00; /* Custom color for the OK button */
  color: #fff; /* Text color */
  border: none; /* Remove default border */
}

.custom-swal-confirm-button:hover {
  background-color: #e07a00; /* Darker shade on hover */
}
.offline-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 1rem;
  z-index: 1000;
}
