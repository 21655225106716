/*lateral main start*/

:root {
  --primary-color: #007bff;
  --border-color: #e0e0e0;
  --text-color: #333;
  --text-secondary: #6c757d;
}

.lateral_main {
  padding-bottom: 98px;
  padding-top: 50px;
  background: radial-gradient(
    46.51% 46.51% at 0% 50%,
    #e6e5e5 0%,
    #f2f2f2 100%
  );
  font-family: "Poppins", sans-serif;
}

.box {
  background: white;
  margin-top: 2rem;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 1px 2px 9px 5px rgba(0, 0, 0, 0.1);
}

.search-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
}

.search-box img {
  width: 14px;
}

.search-input,
.search-select,
.search-button {
  padding: 1rem;
  font-size: 16px;
  background-color: white;
  border: none;
  outline: none;
}

.search-input {
  width: 275px;
  flex: 2 1;
  padding: 6px 15px;
  font-size: 14px;
  color: #222222;
}

.search-input::placeholder {
  color: #222222;
}

.search-select {
  border-left: 1px solid rgb(60 60 60 / 56%);
  cursor: pointer;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 3px;
  font-size: 14px;
  margin-right: 10px;
}

.search-location {
  font-size: 14px;
  border: none;
  width: 6rem;
  outline: none;
  text-align: center;
  padding: 0px 9px;
  border-left: 1px solid rgb(60 60 60 / 56%);
}

.search-location::placeholder {
  color: #222222;
}

.search-button {
  padding: 7px 25px;
  background-color: #f38d00;
  border: 1px solid transparent;
  border-radius: 8px;
  color: white;
  margin: 0px 5px;
}

.search-button:hover {
  background-color: #dc8306;
}

.Popular-Search span {
  color: black;
  font-weight: 500;
}

.Popular-Search {
  padding-top: 1rem;
  font-size: 15px;
}

/*lateral main end*/

/* popular job section start */

.popular-jobs-main {
  padding: 9rem 0;
  font-family: "Poppins", sans-serif;
  width: 70%;
  margin: auto;
}

.job-card {
  border-radius: 8px;
  padding: 2rem 1rem 1rem 1.5rem;
  height: 100%;
  width: 280px;
  min-width: 215px;
  background-color: #fff;
  box-shadow: 3px 2px 17px 0px #0000001c;
}

.lateral-hiring-card-container {
  display: flex;
  justify-content: center;
}

.job-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.company-header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.company-logo {
  width: 60px;
  height: 60px;
  margin-right: 0.5rem;
  object-fit: contain;
}

#company-logo-id {
  width: 19px;
  height: 19px;
  padding: 0;
  border: none;
  border-radius: 0;
}

#top-company-logo-id {
  width: 60px !important;
  height: 60px !important;
  margin-right: 0.5rem !important;
  object-fit: contain !important;
  border: none !important;
  padding: 0 !important;
}

.card-company-name {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.card-job-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.card-job-type {
  color: var(--text-secondary);
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
  margin-top: -4px;
}

.card-job-type small {
  font-size: 13px;
  color: #111111;
}

.job-info {
  padding-top: 15px;
  padding-bottom: 12px;
}

.job-info p {
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
}

.job-apply-btn {
  background-color: #f38d00;
  border: 1px solid transparent !important;
  color: white;
  padding: 7px 10px;
  font-family: "Poppins" sans-serif;
  font-weight: 500;
  border: none;
}

.job-apply-btn:hover {
  background-color: transparent;
  border: 1px solid #f38d00 !important;
  color: #f38d00;
}

.card-text small {
  padding-left: 5px;
  font-size: 13px;
}

.more-details {
  color: #f38d00;
  font-size: 0.8rem;
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.more-details:hover {
  color: #dcd806;
}

.btn_view_all_jobs:hover,
.job-seeker-hero-button {
  margin-top: 2rem;
  color: #fff;
  background-color: #dc8306;
  padding: 9px 18px;
  font-weight: 600;
}

.btn_view_all_jobs {
  background-color: white;
}

.btn_view_all_jobs,
.job-seeker-hero-button:hover {
  margin-top: 2rem;
  color: #dc8306;
  border: 2px solid #dc8306;
  padding: 9px 18px;
  font-weight: 600;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

header p {
  font-size: 1rem;
  color: #222222;
}

/* popular job section end */

/*job seekar end*/
.job_seekers_main {
  position: relative;
  background: url("../../utils/landingpage/images/next_level.svg") no-repeat
    center center;
  background-size: cover;
  text-align: center;
  color: white;
  padding: 50px 0;
  font-family: "Poppins", sans-serif;
}

.job_seekers_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 30, 57, 0.8);
  /* Adjust the opacity as needed */
  z-index: 1;
}

.job_seekers_container {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.job_seekers_container_semi {
  max-width: 800px;
  padding: 20px;
}

.job_seekers_container_content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.job_seekers_container_description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.job_seekers_container_description b {
  font-weight: 300;
}

.job_seekers_content_btn button {
  background-color: #ff7f00;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.job_seekers_content_btn button:hover {
  background-color: #ff9900;
}

/* Think of user section css start */

/* ThinkAbout Component Styles */

.think-about-main {
  height: 500px;
  width: 100%;
  background-color: #011e39;
}

.think-about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;
  margin: auto;
}

.think-about-content {
  height: 100%;
  width: 50%;
}

.think-about-slider-section {
  height: 100%;
  width: 43%;
  display: flex;
  align-items: center;
}

.think-about-slider-container {
  width: 548px;
}

.think-about-box-parent {
  width: 100%;
  height: 357px;
  padding-top: 34px;
  display: inline-block;
}

.think-about-box {
  height: auto;
  position: relative;
  margin-top: 5%;
  width: auto;
  max-width: 250px;
  border-radius: 12px;
  background-color: #fff;
}

.think-about-img {
  position: absolute;
  top: -18%;
  z-index: 1000;
}

.think-about-slider-container .slick-slide img {
  display: block;
  margin-left: 72px !important;
  height: 90px;
  border-radius: 50%;
}

.think-about-content-heading {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  padding-right: 7rem;
}

.think-about-name {
  padding-top: 5rem;
  font-size: 18px;
  height: 145px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.think-about-title {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.think-about-text {
  text-align: center;
  padding: 15px !important;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.custom-arrow img {
  padding: 10px;
  border-radius: 50%;
}

.think-about-content-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 7rem;
}

.think-about-navigation {
  position: absolute;
  bottom: 22%;
  left: 0;
  display: flex;
  gap: 10px;
  display: none;
}

.custom-arrow {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}

.prev-arrow {
  background-color: #ffa500;
  /* Orange color for the left arrow */
}

.next-arrow {
  background-color: #fff;
}

.letral-hiring-row {
  margin-top: 5rem;
}

.loged_user_details_container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.loged_user_details_container button {
  background: none;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 50%;
  height: fit-content;
}

.loged_user_details_container button:hover {
  border: 1px solid #f38d00;
}

/* Think of user section css end */

@media (max-width: 576px) and (min-width: 180px) {
  .job_seekers_container_content h2 {
    font-size: 24px;
    padding: 0 4px;
  }

  .job_seekers_container_description b {
    font-size: 12px;
  }

  .job_seekers_content_btn button {
    padding: 8px 24px;
    background-color: #f38d00;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 10px;
    font-weight: 600;
  }

  .job_seekers_content_btn button:hover {
    background-color: #dd850c;
  }
}

/*job seekar end*/

/* companies start */
.companies-main {
  font-family: Poppins;
  color: black;
  padding-top: 9rem;
  padding-bottom: 9rem;
  text-align: center;
  width: 58%;
  margin: auto;
}

header {
  padding: 20px;
  background-color: #fff;
  margin-bottom: 20px;
}

.companies-main header h1 {
  font-size: 49px;
  font-weight: 600;
  line-height: 52.8px;
  text-align: center;
}

.companies-main header p {
  font-size: 17px;
  font-weight: 400;
  line-height: 21.76px;
  text-align: center;
  padding-top: 10px;
}

.companies-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.company-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 12px 4px rgba(0, 0, 0, 0.16);
  width: 100%;
  height: 140px;
  padding: 20px;
  text-align: center;
  transition: transform 0.2s;
}

.company-card:hover {
  transform: translateY(-10px);
}

.think-about-content-heading-new-text {
  font-size: 1.9rem;
  font-weight: 600;
  color: #f38d00;
}

.think-about-content-heading-new-text-font {
  font-size: 16px;
}

.compay {
  display: flex;
  align-items: center;
  gap: 10px;
}

.position {
  margin-top: 1.5rem;
  float: left;
  font-weight: 600;
  color: #f38d00;
}

.company-info {
  text-align: left;
}

.company-info h3 {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.company-info a {
  text-decoration: none;
}

.company-info a:hover {
  text-decoration: underline;
}

.arrow-btn {
  background-color: white;
  border: 1px solid #f38d00;
  padding: 1.2rem 1.2rem;
  cursor: pointer;
  margin-right: 1rem;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

.arrow-btn:hover.left-arrow {
  background-image: url("../../utils/landingpage/images/hover-left-arrow.svg");
}

.arrow-btn:hover.right-arrow {
  background-image: url("../../utils/landingpage/images/hover-right-arrow.svg");
}

.arrow-btn:hover {
  background-color: #f38d00;
}

/* companies end */

/* media  */
@media (max-width: 1650px) and (min-width: 1449px) {
  .popular-jobs-main,
  .companies-main,
  .think-about-container {
    width: 90%;
  }
}

@media (max-width: 1450px) and (min-width: 1199px) {
  .companies-main,
  .think-about-container {
    width: 90%;
  }

  .think-about-content-wrapper {
    padding-top: 4rem;
  }

  .letral-image-hero img {
    width: 90%;
  }

  .hero_content h1 {
    font-size: 50px;
  }

  .search-input {
    width: 150px;
    font-size: 12px;
  }

  .popular-jobs-main {
    width: 100%;
  }

  .card-title {
    font-size: 16px;
  }

  .think-about-slider-section {
    width: 45%;
  }
}

@media (max-width: 1200px) and (min-width: 991px) {
  .companies-main,
  .think-about-container {
    width: 90%;
  }
  .think-about-content-wrapper {
    padding-top: 5rem;
  }

  .letral-image-hero {
    display: grid;
    place-items: center;
    padding-top: 50px;
  }

  .letral-image-hero img {
    width: 100%;
  }

  .hero_content h1 {
    font-size: 46px;
  }

  .hero_content_btn button {
    padding: 6px 24px;
  }

  .search-input {
    width: 150px;
    font-size: 12px;
  }

  .search-button {
    padding: 5px 20px;
  }

  .Popular-Search {
    font-size: 13px;
  }

  .popular-jobs-main {
    width: 80%;
  }

  .job-seeker-hero-section {
    padding: 100px 120px;
  }

  .job-seeker-hero-title {
    font-size: 32px;
  }

  .job-seeker-hero-subtitle {
    font-size: 16px;
  }

  .card-title {
    font-size: 14px;
  }

  .think-about-slider-section {
    width: 55%;
  }

  .think-about-content-heading {
    font-size: 12px !important;
    padding-right: 2rem;
  }
  .think-about-navigation {
    display: none;
  }
  .think-about-main {
    height: 550px;
  }

  .think-about-content-heading-new-text {
    font-size: 1.7rem;
  }

  .think-about-content-heading-new-text-font {
    font-size: 15px;
  }
}

@media (max-width: 992px) and (min-width: 767px) {
  .companies-main {
    width: 80%;
  }

  .letral_content h1,
  .letral_content p {
    text-align: center;
  }

  .letral-image-hero {
    display: grid;
    place-items: center;
    padding-top: 50px;
  }

  .letral-image-hero img {
    width: 85%;
  }

  .hero_content h1 {
    font-size: 46px;
  }

  .hero_content_btn button {
    padding: 6px 24px;
  }

  .search-input {
    width: 150px;
    font-size: 12px;
  }

  .search-button {
    padding: 5px 20px;
  }

  .Popular-Search {
    font-size: 13px;
  }

  .hero_row {
    padding: 0 0 0 0;
  }

  .popular-jobs-main {
    width: 80%;
  }

  .job-seeker-hero-section {
    padding: 70px 80px;
  }

  .job-seeker-hero-title {
    font-size: 32px;
  }

  .job-seeker-hero-subtitle {
    font-size: 16px;
  }

  .company-logo {
    height: 40px;
    width: 40px;
  }

  .card-title,
  .position {
    font-size: 14px;
  }

  .think-about-main {
    height: 800px;
  }

  .think-about-container {
    width: 90%;
    flex-direction: column;
  }

  .think-about-content-wrapper {
    padding-top: 5rem;
  }

  .think-about-content-heading {
    font-size: 12px;
    text-align: center;
    padding-right: 0rem;
  }

  .think-about-content {
    width: 100%;
  }

  .think-about-navigation {
    left: 42.5%;
    display: none;
  }

  .think-about-slider-section {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 768px) and (min-width: 575px) {
  .popular-jobs-main,
  .think-about-container,
  .companies-main {
    width: 80%;
  }

  .letral_content h1,
  .letral_content p {
    text-align: center;
  }

  .letral-image-hero {
    display: grid;
    place-items: center;
    padding-top: 50px;
  }

  .letral-image-hero img {
    width: 80%;
  }

  .hero_content h1 {
    font-size: 46px;
  }

  .hero_content_btn button {
    padding: 6px 24px;
  }

  .search-input {
    width: 140px;
    font-size: 12px;
  }

  .search-button {
    padding: 5px 20px;
    margin: 0px;
  }

  .Popular-Search {
    font-size: 13px;
  }

  .hero_row {
    padding: 0 0 0 0;
  }

  .company-logo {
    height: 40px;
    width: 40px;
  }

  .card-job-title {
    font-size: 14px;
  }

  .card-title,
  .position {
    margin-top: 0.5rem;
    font-size: 14px;
  }

  .think-about-main {
    height: 800px;
  }

  .think-about-container {
    width: 90%;
    flex-direction: column;
  }

  .think-about-content-wrapper {
    padding-top: 2rem;
  }

  .think-about-content-heading {
    font-size: 13px;
    text-align: center;
    padding-right: 0rem;
  }

  .think-about-content {
    width: 100%;
  }

  .think-about-navigation {
    left: 42.5%;
    display: none;
  }

  .think-about-slider-section {
    width: 100%;
    justify-content: center;
  }

  .think-about-slider-container {
    width: 530px;
  }

  .job_seekers_container_content h2 {
    font-size: 36px;
    padding: 0px 50px 0px 50px;
  }

  .job_seekers_container_description b {
    font-size: 14px;
  }

  .job_seekers_content_btn button {
    padding: 9px 27px;
    background-color: #f38d00;
    color: #ffffff;
    border: 1px solid transparent;
    border-radius: 10px;
    font-weight: 600;
  }
}

@media (max-width: 576px) and (min-width: 150px) {
  .popular-jobs-main,
  .companies-main,
  .think-about-container {
    width: 80%;
    padding-top: 2rem;
  }

  .letral_content h1 {
    font-size: 1.5rem;
  }

  .letral_content h1,
  .letral_content p {
    text-align: center;
  }

  .letral-image-hero img {
    width: 100%;
    padding-top: 51px;
  }

  .hero_content h1 {
    font-size: 28 px;
  }

  .hero_content_btn button {
    padding: 6px 24px;
  }

  .hero_content {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hero_content .box {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .search-box {
    display: flex;
    flex-direction: column;
    padding-right: 4%;
  }

  .search-box img {
    display: none;
  }

  .search-input,
  .search-location,
  .search-select {
    padding: 12px;
    width: 100%;
    margin: 0px;
    margin-top: 15px;
    font-size: 14px;
    border: none;
    background-color: white;
    border-bottom: 0.12rem solid #ccc;
    border-radius: 3px;
    text-align: start;
  }

  .search-select {
    padding: 12px;
    font-size: 14px;
  }

  .search-button {
    width: 100%;
    padding: 10px;
    background-color: #f38d00;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    margin-top: 15px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .search-button:hover {
    background-color: #f38e00d8;
  }

  .Popular-Search {
    font-size: 11px;
  }

  .hero_row {
    padding: 0 0 0 0;
  }

  .popular-jobs-main header h1 {
    font-size: 32px;
  }

  .popular-jobs-main header p {
    font-size: 13px;
  }

  .company-logo {
    height: 40px;
    width: 40px;
  }

  .card-title,
  .position {
    font-size: 14px;
  }

  .think-about-main {
    height: 800px;
  }

  .think-about-container {
    width: 100%;
    flex-direction: column;
  }

  .think-about-content-wrapper {
    padding-top: 2rem;
  }

  .think-about-content-heading {
    font-size: 13px;
    text-align: center;
    padding-right: 0rem;
  }

  .think-about-content {
    width: 80%;
  }

  .think-about-navigation {
    left: 50%;
    transform: translate(-50%, 20%);
    display: none;
  }

  .think-about-box-parent {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 75px;
  }

  .companies-main header h1 {
    font-size: 32px;
  }
  .think-about-slider-section {
    width: 100%;
    justify-content: center;
  }

  .think-about-slider-container {
    width: 260px;
  }
  .think-about-content-heading-new-text {
    font-size: 1.6rem;
  }

  .think-about-content-heading-new-text-font {
    font-size: 14px;
  }
}

@media (max-width: 440px) and (min-width: 150px) {
  .think-about-main {
    height: 850px;
  }
}
